import React from "react"
import "../../mystyles.scss"

const boucle1 = [
  {
    name: "Marc-Antoine Levionnois",
    temps: "1:29:45",
  },
  {
    name: "Christophe Heckel",
    temps: "1:40:50",
  },
  {
    name: "Igor Francey",
    temps: "1:50:50",
  },
  {
    name: "Carole Thalmann",
    temps: "2:03:30",
  },
  {
    name: "Céline Guillaume",
    temps: "2:06:52",
  },
  {
    name: "Joanne Heckel",
    temps: "2:08:51",
  },
  {
    name: "Tiffany Stauffer",
    temps: "2:08:52",
  },
  {
    name: "Florence Francey",
    temps: "2:21:13",
  },
  {
    name: "Héloïse Coen",
    temps: "2:22:09",
  },
  {
    name: "Marc-Adrien Coen",
    temps: "2:22:18",
  },
]

const boucle2 = [
  {
    name: "Jonathan Vonlanthen",
    temps: "0:54:20",
  },
  {
    name: "Juliane Ramuz",
    temps: "1:01:25",
  },
  {
    name: "Cassandre Chable",
    temps: "1:01:43",
  },
  {
    name: "Mélanie Pereira",
    temps: "1:02:24",
  },
]

export default function Resultat() {
  return (
    <div className="container">
      <h1 className="title">Résultats du 13 mai 2021 : Joey under the Sun</h1>
      <div className="columns">
        <div className="column">
          <h2 className="subtitle"> Semi-marathon </h2>
          <table class="table">
            <thead>
              <tr>
                <th>Nom et Prénom</th>
                <th>Temps</th>
              </tr>
            </thead>
            <tbody>
              {boucle1.map(({ name, temps }) => (
                <tr>
                  <td>{name}</td>
                  <td>{temps}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="column">
          <h2 className="subtitle"> 10km </h2>
          <table class="table">
            <thead>
              <tr>
                <th>Nom et Prénom</th>
                <th>Temps</th>
              </tr>
            </thead>
            <tbody>
              {boucle2.map(({ name, temps }) => (
                <tr>
                  <td>{name}</td>
                  <td>{temps}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
