import React from "react"
import "../../mystyles.scss"

const boucle1 = [
  {
    name: "Francey Igor",
    depart: "18:00",
    arrival: "02:00",
    nbLoop: 16,
  },
  {
    name: "Levionnois Marc-Antoine",
    depart: "18:00",
    arrival: "02:00",
    nbLoop: 16,
  },
  {
    name: "Thalmann Carole",
    depart: "18:00",
    arrival: "01:30",
    nbLoop: 15,
  },
  {
    name: "Vonlanthen Jonathan",
    depart: "18:00",
    arrival: "01:30",
    nbLoop: 15,
  },
  {
    name: "Luu Quoc Bao",
    depart: "18:00",
    arrival: "00:00",
    nbLoop: 12,
  },
  {
    name: "Sautaux Benny",
    depart: "18:00",
    arrival: "23:00",
    nbLoop: 10,
  },
  {
    name: "Heckel Christophe",
    depart: "18:00",
    arrival: "22:30",
    nbLoop: 9,
  },
  {
    name: "Francey Florence",
    depart: "18:00",
    arrival: "22:00",
    nbLoop: 8,
  },
  {
    name: "Kohler Raoul",
    depart: "18:00",
    arrival: "21:30",
    nbLoop: 7,
  },
  {
    name: "Jordan Jérémy",
    depart: "18:00",
    arrival: "21:00",
    nbLoop: 6,
  },
  {
    name: "Heckel Joanne",
    depart: "18:00",
    arrival: "21:00",
    nbLoop: 6,
  },
  {
    name: "Coen Marc-Adrien",
    depart: "19:30",
    arrival: "22:30",
    nbLoop: 6,
  },
]

const boucle2 = [
  {
    name: "Cusin Albane",
    depart: "18:00",
    arrival: "22:30",
    nbLoop: 9,
  },
  {
    name: "Altermatt Philip",
    depart: "18:00",
    arrival: "21:00",
    nbLoop: 6,
  },
  {
    name: "Pereira Carvalho Mélanie",
    depart: "18:00",
    arrival: "21:00",
    nbLoop: 6,
  },
  {
    name: "Levionnois Marion",
    depart: "18:00",
    arrival: "20:30",
    nbLoop: 5,
  },
]

export default function Resultat() {
  return (
    <div className="container">
      <h1 className="title">
        Résultats du 13 novembre 2021 : La Maximal Race 2021
      </h1>
      <div className="columns">
        <div className="column">
          <h2 className="subtitle"> Boucle 1 (2.8km)</h2>
          <table class="table">
            <thead>
              <tr>
                <th>Nom et Prénom</th>
                <th>Départ</th>
                <th>Arrivé</th>
                <th>#Boucles</th>
                <th>Distance</th>
              </tr>
            </thead>
            <tbody>
              {boucle1.map(({ name, depart, arrival, nbLoop }) => (
                <tr>
                  <td>{name}</td>
                  <td>{depart}</td>
                  <td>{arrival}</td>
                  <td>{nbLoop}</td>
                  <td>{(nbLoop * 2.8).toFixed(2)} km</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="column">
          <h2 className="subtitle"> Boucle 2 (2.5km)</h2>
          <table class="table">
            <thead>
              <tr>
                <th>Nom et Prénom</th>
                <th>Départ</th>
                <th>Arrivé</th>
                <th>#Boucles</th>
                <th>Distance</th>
              </tr>
            </thead>
            <tbody>
              {boucle2.map(({ name, depart, arrival, nbLoop }) => (
                <tr>
                  <td>{name}</td>
                  <td>{depart}</td>
                  <td>{arrival}</td>
                  <td>{nbLoop}</td>
                  <td>{(nbLoop * 2.5).toFixed(2)} km</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
