import React from "react"
import "../../mystyles.scss"

const res = [
  { nom: "Christophe Heckel", temps: "20:25" },
  { nom: "Jules Rossier", temps: "21:55" },
  { nom: "Benny Sautaux", temps: "21:40" },
  { nom: "Joanne Heckel", temps: "26:36" },
  { nom: "Jérémy Jordan", temps: "26:35" },
  { nom: "Frannçois G.", temps: "25:59" },
  { nom: "Marc-Adrien Coen", temps: "31:27" },
  { nom: "Héloïse S. Coen", temps: "30:01" },
]

export default function Resultat() {
  // sort res by temps
  res.sort((a, b) => {
    if (a.temps < b.temps) return -1
    if (a.temps > b.temps) return 1
    return 0
  })

  return (
    <div className="container">
      <h1 className="title">Résultats du 29 juin 2022 : 5km</h1>
      <div className="columns">
        <div className="column">
          <h2 className="subtitle"> 5km en stade</h2>
          <table class="table">
            <thead>
              <tr>
                <th>Nom et Prénom</th>
                <th>Temps</th>
              </tr>
            </thead>
            <tbody>
              {res.map(({ nom, temps }) => (
                <tr>
                  <td>{nom}</td>
                  <td>{temps}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
