import React from "react"
import "../../mystyles.scss"

const boucle1 = [
  {
    name: "Jeanne Barras",
    depart: "18:30",
    arrival: "00:00",
    nbLoop: 11,
  },
  {
    name: "Marc-Antoine Levionnois",
    depart: "18:30",
    arrival: "00:00",
    nbLoop: 11,
  },
  {
    name: "Bao Luu",
    depart: "18:30",
    arrival: "23:30",
    nbLoop: 10,
  },
  {
    name: "Joanne Heckel",
    depart: "18:30",
    arrival: "22:00",
    nbLoop: 7,
  },
]

const boucle2 = [
  {
    name: "Mélissa Levionnois",
    depart: "19:00",
    arrival: "22:30",
    nbLoop: 7,
  },
  {
    name: "Florence Francey",
    depart: "19:00",
    arrival: "22:30",
    nbLoop: 7,
  },
  {
    name: "Marc-Adrien Coen",
    depart: "19:00",
    arrival: "22:30",
    nbLoop: 6,
  },
  {
    name: "Raoul Kohler",
    depart: "19:00",
    arrival: "22:00",
    nbLoop: 5,
  },
  {
    name: "Jérémy Jordan",
    depart: "19:00",
    arrival: "20:30",
    nbLoop: 3,
  },
]

export default function Resultat() {
  return (
    <div className="container">
      <h1 className="title">
        Résultats du 28 novembre 2020 : La Maximal Race première édition
      </h1>
      <div className="columns">
        <div className="column">
          <h2 className="subtitle"> Boucle 1 (2.8km)</h2>
          <table class="table">
            <thead>
              <tr>
                <th>Nom et Prénom</th>
                <th>Départ</th>
                <th>Arrivé</th>
                <th>#Boucles</th>
                <th>Distance</th>
              </tr>
            </thead>
            <tbody>
              {boucle1.map(({ name, depart, arrival, nbLoop }) => (
                <tr>
                  <td>{name}</td>
                  <td>{depart}</td>
                  <td>{arrival}</td>
                  <td>{nbLoop}</td>
                  <td>{nbLoop * 2.75} km</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="column">
          <h2 className="subtitle"> Boucle 2 (2.5km)</h2>
          <table class="table">
            <thead>
              <tr>
                <th>Nom et Prénom</th>
                <th>Départ</th>
                <th>Arrivé</th>
                <th>#Boucles</th>
                <th>Distance</th>
              </tr>
            </thead>
            <tbody>
              {boucle2.map(({ name, depart, arrival, nbLoop }) => (
                <tr>
                  <td>{name}</td>
                  <td>{depart}</td>
                  <td>{arrival}</td>
                  <td>{nbLoop}</td>
                  <td>{nbLoop * 2.5} km</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
