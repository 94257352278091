import React from "react"
import Layout from "../../components/layout/layout"
import SmallHero from "../../components/layout/smallHero"

import Resultat13092020 from "./resultats/13092020.js"
import Resultat07062020 from "./resultats/07062020.js"
import ResultatMaxRace2020 from "./resultats/MaxRace2020.js"
import ResultatMaxRace2021 from "./resultats/MaxRace2021.js"
import ResultatJoey from "./resultats/joey_under_the_sun.js"
import Resultat20211201 from "./resultats/20211201.js"
import Resultat20220629 from "./resultats/20220629.js"
import { Helmet } from "react-helmet"

import "../mystyles.scss"

export default function Home() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Resultats</title>
      </Helmet>
      <SmallHero title="Résultats" color="is-success" />
      <article className="section">
        <Resultat20220629 />
        <br />
        <Resultat20211201 />
        <br />
        <ResultatMaxRace2021 />
        <br />
        <ResultatJoey />
        <br />
        <ResultatMaxRace2020 />
        <br />
        <Resultat13092020 />
        <br />
        <Resultat07062020 />
      </article>
    </Layout>
  )
}
