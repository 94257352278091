import React from "react"
import "../../mystyles.scss"

export default function Resultat() {
  return (
    <div className="container">
      <h1 className="title">
        Résultats du 13 septembre 2020 : Les 5km des feuilles qui tombent
      </h1>
      <div className="columns">
        <div className="column">
          <h2 className="subtitle"> Parcours 1 (5km)</h2>
          <table class="table">
            <thead>
              <tr>
                <th>Nom et Prénom</th>
                <th>Temps</th>
                <th>Allure</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Marc-Antoine Levionnois</td>
                <td>19:42</td>
                <td>3'56/km</td>
              </tr>
              <tr>
                <td>Christophe Heckel</td>
                <td>21:03</td>
                <td>4'12/km</td>
              </tr>
              <tr>
                <td>Igor Francey</td>
                <td>23:23</td>
                <td>4'40/km</td>
              </tr>
              <tr>
                <td>Jonathen Vonlanthen</td>
                <td>23:32</td>
                <td>4'42/km</td>
              </tr>
              <tr>
                <td>Bao Luu</td>
                <td>24:07</td>
                <td>4'49/km</td>
              </tr>
              <tr>
                <td>Julie Beuret</td>
                <td>25:13</td>
                <td>5'02/km</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="column">
          <h2 className="subtitle"> Parcours 2 (2.8km)</h2>
          <table class="table">
            <thead>
              <tr>
                <th>Nom et Prénom</th>
                <th>Temps</th>
                <th>Allure</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Marion Levionnois</td>
                <td>15:20</td>
                <td>5'28/km</td>
              </tr>
              <tr>
                <td>Héloïse Coen</td>
                <td>15:28</td>
                <td>5'31/km</td>
              </tr>
              <tr>
                <td>Mélanie Carvalho</td>
                <td>16:51</td>
                <td>6'01/km</td>
              </tr>
              <tr>
                <td>Soraya Esteves </td>
                <td>17:13</td>
                <td>6'08/km</td>
              </tr>
              <tr>
                <td>Julie Brodard</td>
                <td>24:07</td>
                <td>6'52/km</td>
              </tr>
              <tr>
                <td>Emilie Brodard</td>
                <td>24:07</td>
                <td>6'52/km</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="column">
          <h2 className="subtitle"> Parcours 3 (7.8km)</h2>
          <table class="table">
            <thead>
              <tr>
                <th>Nom et Prénom</th>
                <th>Temps</th>
                <th>Allure</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Joanne Heckel</td>
                <td>41:24</td>
                <td>5'18/km</td>
              </tr>
              <tr>
                <td>Tiffany Stauffer </td>
                <td>41:40</td>
                <td>5'20/km</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
